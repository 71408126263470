



































































import { Notify,ImagePreview  } from "vant";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import { AuthentData } from "../../Type/index"
import Stroage from "../../util/Storage"
import { Vue, Component } from "vue-property-decorator";
import { GetAuthentSuc } from "../../Api/Basics/index"
import ZoomPage from "@/impView/PageSafety";

interface AuthentSucType<T = string> {
  authType: number | T;
  title: T;
  otherTitle: T;
  ImgHide: T;
  leftImg: T;
  RightImg: T;
  otherImg: T;
  userId:T;
  token:T;
  loadingShow:boolean;
  data:AuthentData | undefined;

  initData():void;
  handleUpSuc():void;
  handlePhotoPreview(data?:T): void;
  handleError(data: string): void;
}

@Component({ name: "AuthentSuc",components:{ HeadTop } })
export default class AuthentSuc extends ZoomPage implements AuthentSucType {
  title = "学生认证详情";
  otherTitle = "学生认证";
  ImgHide = "一卡通照片";
  authType: string | number = 0;
  leftImg = require("../../assets/icon/Authent/FaceImg.png");
  RightImg = require("../../assets/icon/Authent/BackImg.png");
  otherImg = require("../../assets/icon/Authent/OrderImg.png");
  userId = "";
  token = "";
  loadingShow = true;
  data:AuthentData | undefined = {};


  mounted() {
    this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
    this.token = Stroage.GetData_ && Stroage.GetData_("token")
    // 初始化
    this.initData()
    // 数据请求
    this.handleUpSuc()
  }

  initData(){
    this.title = this.$route.query.name && (this.$route.query.name as string) + "详情";
    this.authType = this.$route.query.authType && (this.$route.query.authType as string);
    switch (Number(this.$route.query.authType)) {
      case 0:
        this.otherTitle = "一卡通";
        this.ImgHide = "一卡通照片";
        break;
      case 1:
        this.otherTitle = "骑手编号";
        this.ImgHide = "工作照";
        break;
      case 2:
        this.otherTitle = "营业执照";
        this.ImgHide = "营业执照";
        break;
      case 3:
        this.otherTitle = "营业执照";
        this.ImgHide = "营业执照";
        break;
    }
  }

  handlePhotoPreview( src:string ) {
    if( !!src ){
        ImagePreview({
            images:[src],
            startPosition:0
        })
    }
  }

  handleUpSuc(){
      GetAuthentSuc({
          userId:this.userId,
          token:this.token
      },{
          authType:this.authType
      }).then( (res:any)=>{
          this.loadingShow = false
          console.log( res )
          if( res.message.code === "200" ){
            this.data = res.data
          }else{
              this.handleError(res.message.msg)
          }
      } )
  }

  handleError(data: string) {
    let str = "";
    if (data.length) {
      str = data;
    } else {
      str = "网络繁忙...";
    }
    Notify({
      message: str,
      color: "white",
      background: "rgba(0,0,0,.6)",
    });
  }
}
